import { useState, useEffect } from "react";
import "./Register.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const locationData = {
  Kerala: {
    Thiruvananthapuram: {
      Taluks: [
        "Neyyattinkara",
        "Kattakada",
        "Thiruvananthapuram",
        "Chirayinkeezhu",
        "Varkala",
      ],
      Corporations: ["Thiruvananthapuram"],
      Panchayats: [
        "Attingal",
        "Nedumangad",
        "Varkala",
        "Kadinamkulam",
        "Balaramapuram",
      ],
    },
    Kollam: {
      Taluks: ["Kollam", "Kunnathur", "Karunagappally", "Sasthamkotta"],
      Corporations: ["Kollam"],
      Panchayats: [
        "Chavara",
        "Kottarakkara",
        "Pathanapuram",
        "Punalur",
        "Sasthamcotta",
      ],
    },
    Pathanamthitta: {
      Taluks: ["Adoor", "Thiruvalla", "Ranni", "Konni"],
      Corporations: [],
      Panchayats: ["Ranni", "Adoor", "Mallapally", "Koipuram", "Kadambanad"],
    },
    Alappuzha: {
      Taluks: ["Ambalapuzha", "Cherthala", "Kuttanad", "Chengannur"],
      Corporations: [],
      Panchayats: [
        "Kuttanad",
        "Mannar",
        "Chengannur",
        "Thalavady",
        "Ambalapuzha",
      ],
    },
    Kottayam: {
      Taluks: ["Kottayam", "Vaikom", "Changanassery", "Meenachil"],
      Corporations: [],
      Panchayats: [
        "Ettumanoor",
        "Pala",
        "Changanassery",
        "Kuravilangad",
        "Kaduthuruthy",
      ],
    },
    Idukki: {
      Taluks: ["Idukki", "Thodupuzha", "Devikulam", "Peerumedu"],
      Corporations: [],
      Panchayats: ["Vazhathope", "Adimali", "Munnar", "Kattappana", "Kumily"],
    },
    Ernakulam: {
      Taluks: ["Kochi", "Kunnathunad", "Paravur", "Aluva"],
      Corporations: ["Kochi"],
      Panchayats: [
        "Muvattupuzha",
        "Kothamangalam",
        "Angamaly",
        "Perumbavoor",
        "Thrikkakara",
      ],
    },
    Thrissur: {
      Taluks: ["Thrissur", "Chalakudy", "Kodungallur", "Kunnamkulam"],
      Corporations: ["Thrissur"],
      Panchayats: [
        "Chavakkad",
        "Irinjalakuda",
        "Kunnamkulam",
        "Guruvayoor",
        "Chalakudy",
      ],
    },
    Palakkad: {
      Taluks: ["Palakkad", "Chittur", "Mannarkkad", "Ottappalam"],
      Corporations: [],
      Panchayats: [
        "Nenmara",
        "Alathur",
        "Cherpulassery",
        "Mannarkkad",
        "Ottappalam",
      ],
    },
    Malappuram: {
      Taluks: ["Malappuram", "Tirur", "Ponnani", "Nilambur"],
      Corporations: [],
      Panchayats: [
        "Tirurangadi",
        "Manjeri",
        "Ponnani",
        "Perinthalmanna",
        "Kondotty",
      ],
    },
    Kozhikode: {
      Taluks: ["Kozhikode", "Vatakara", "Koyilandy"],
      Corporations: ["Kozhikode"],
      Panchayats: [
        "Balussery",
        "Perambra",
        "Kunnamangalam",
        "Vadakara",
        "Thiruvambady",
      ],
    },
    Wayanad: {
      Taluks: ["Sultan Bathery", "Mananthavady", "Vythiri"],
      Corporations: [],
      Panchayats: [
        "Kalpetta",
        "Sulthan Bathery",
        "Mananthavady",
        "Vythiri",
        "Meppadi",
      ],
    },
    Kannur: {
      Taluks: ["Kannur", "Taliparamba", "Thalassery", "Payyannur"],
      Corporations: [],
      Panchayats: [
        "Iritty",
        "Mattannur",
        "Taliparamba",
        "Payyannur",
        "Kuthuparamba",
      ],
    },
    Kasargod: {
      Taluks: ["Kasargod", "Hosdurg", "Manjeshwaram"],
      Corporations: [],
      Panchayats: ["Kanhangad", "Udma", "Manjeshwar", "Kasargod", "Hosdurg"],
    },
  },
};

const Register = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [referralId, setReferralId] = useState("");
  const [input, setInput] = useState({
    first_name: "",
    mobile: "",
    pincode: "",
    password: "",
    confirm_password: "",
    referral_id: "",
    panchayat: "",
    taluk: "",
    district: "",
    corporation: "",
    sponsor_id:"",
  });

  const [district, setDistrict] = useState("");
  const [taluk, setTaluk] = useState("");
  const [corporation, setCorporation] = useState("");
  const [panchayat, setPanchayat] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [userlogin, setUserlogin] = useState(null);


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralId = params.get("referral_id");
    if (referralId) {
      setReferralId(referralId);
    }
  }, [location]);

  useEffect(() => {
    setInput((prevInput) => ({
      ...prevInput,
      referral_id: referralId,
    }));
  }, [referralId]);

  const notify = (message) => {
    toast(message);
  };

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setInput((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.first_name || !input.mobile || !input.pincode || !input.password || !input.referral_id || !input.confirm_password) {
      notify("Please enter all fields");
    } else if (input.password !== input.confirm_password) {
      notify("Passwords do not match");
    } else if (input.pincode.length !== 6) {
      notify("Pincode must contain exactly 6 digits");
    } else if (input.mobile.length !== 10) {
      notify("Mobile number must contain exactly 10 digits");
    } else {
      setLoading(true);
      try {
        const response = await axios.post("https://lunarsenterprises.com:3004/mlm/user-registration", input);
        if (response.data.status === true) {
          navigate("/post_login", {
            state: { input: input, referral: response.data.referral_id },
          });
        }
      } catch (error) {
        console.error(error);
        notify("Registration failed, please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const districts = Object.keys(locationData.Kerala);

  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setDistrict(selectedDistrict);
    setTaluk("");
    setCorporation("");
    setPanchayat("");

    setInput((prevInput) => ({
      ...prevInput,
      district: selectedDistrict,
      taluk: "",
      corporation: "",
      panchayat: "",
    }));
  };

  const handleTalukChange = (e) => {
    const selectedTaluk = e.target.value;
    setTaluk(selectedTaluk);
    setInput((prevInput) => ({
      ...prevInput,
      taluk: selectedTaluk,
    }));
  };

  const handleCorporationChange = (e) => {
    const selectedCorporation = e.target.value;
    setCorporation(selectedCorporation);
    setInput((prevInput) => ({
      ...prevInput,
      corporation: selectedCorporation,
    }));
  };

  const handlePanchayatChange = (e) => {
    const selectedPanchayat = e.target.value;
    setPanchayat(selectedPanchayat);
    setInput((prevInput) => ({
      ...prevInput,
      panchayat: selectedPanchayat,
    }));
  };

  return (
    <div className="container-fluid register">
      <form onSubmit={handleSubmit}>
        <h4>Create an Account</h4>

        <Form.Label htmlFor="referral_id">Referral Id</Form.Label>
        <Form.Control
          type="text"
          id="referral_id"
          placeholder="Enter your referral ID number"
          value={input.referral_id}
          onChange={onHandleChange}
          required
        />

<Form.Label htmlFor="sponsor_id">Sponsor Id</Form.Label>
        <Form.Control
          type="text"
          id="sponsor_id"
          placeholder="Enter your Sponsor ID number"
          value={input.sponsor_id}
          onChange={onHandleChange}
          // required
        />

        <Form.Label htmlFor="first_name">Name</Form.Label>
        <Form.Control
          type="text"
          id="first_name"
          placeholder="Enter your name"
          value={input.first_name}
          onChange={onHandleChange}
          required
        />

        <Form.Label htmlFor="mobile">Mobile</Form.Label>
        <Form.Control
          type="text"
          id="mobile"
          placeholder="Enter your mobile number"
          value={input.mobile}
          onChange={onHandleChange}
          required
        />

        <Form.Label htmlFor="pincode">Pincode</Form.Label>
        <Form.Control
          type="text"
          id="pincode"
          placeholder="Enter your pincode"
          value={input.pincode}
          onChange={onHandleChange}
          required
        />

        <Form.Group controlId="district">
          <Form.Label>District</Form.Label>
          <Form.Select value={district} onChange={handleDistrictChange}>
            <option value="">-- Select District --</option>
            {districts.map((districtName, index) => (
              <option key={index} value={districtName}>
                {districtName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        {district && (
          <Form.Group controlId="taluk">
            <Form.Label>Select Taluk</Form.Label>
            <Form.Select value={taluk} onChange={handleTalukChange}>
              <option value="">-- Select Taluk --</option>
              {locationData.Kerala[district].Taluks.map((talukName, index) => (
                <option key={index} value={talukName}>
                  {talukName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}

        {district && (
          <Form.Group controlId="corporation">
            <Form.Label>Select Corporation</Form.Label>
            <Form.Select value={corporation} onChange={handleCorporationChange}>
              <option value="">Select Corporation</option>
              {locationData.Kerala[district].Corporations.map((corpName, index) => (
                <option key={index} value={corpName}>
                  {corpName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}

        {taluk && (
          <Form.Group controlId="panchayat">
            <Form.Label>Select Panchayat</Form.Label>
            <Form.Select value={panchayat} onChange={handlePanchayatChange}>
              <option value="">-- Select Panchayat --</option>
              {locationData.Kerala[district].Panchayats.map((panchayatName, index) => (
                <option key={index} value={panchayatName}>
                  {panchayatName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}

        <Form.Label htmlFor="password">Password</Form.Label>
        <Form.Control
          type={passwordVisible ? "text" : "password"}
          id="password"
          placeholder="Enter your password"
          value={input.password}
          onChange={onHandleChange}
          required
        />
        <FaEyeSlash onClick={togglePasswordVisibility} />

        <Form.Label htmlFor="confirm_password">Confirm Password</Form.Label>
        <Form.Control
          type={passwordVisible ? "text" : "password"}
          id="confirm_password"
          placeholder="Confirm your password"
          value={input.confirm_password}
          onChange={onHandleChange}
          required
        />

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Register"}
        </Button>
      </form>

      <ToastContainer />
    </div>
  );
};

export default Register;