import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // If you're using location
import moment from "moment";
import axios from "axios";

const Widthhistory = () => {
  // Retrieve the user_id from localStorage
  const storedUserId = localStorage.getItem("user_id");
  console.log("Stored user_id:", storedUserId);

  // State for user history
  const [userhistory, setUserhistory] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for better UX
  const [error, setError] = useState(null); // Error state

  // Fetch user withdrawal history on component mount
  useEffect(() => {
    fetchUserHistory();
  }, []);

  const fetchUserHistory = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/withdraw/history",
        {}, // Send the appropriate data if necessary in the request body
        {
          headers: {
            user_id: storedUserId, // Pass the user_id in headers
          },
        }
      );

      // Set the fetched data in state
      setUserhistory(response.data.data);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      setLoading(false); // Set loading to false even on error
      setError("Error fetching data. Please try again."); // Set error message
      console.error("Error fetching user history:", error);
    }
  };

  return (
    <div className="container-fluid myprofile py-5">
      <h4 className="text-white">Withdrawal History</h4>

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div className="table-responsive mt-4">
          <table className="table table-light table-hover shadow">
            <thead>
              <tr>
                <th scope="col">Sl no</th>
                <th scope="col">Amount Received</th>
                <th scope="col">Approve Date</th>
                <th scope="col">Date</th>
                <th scope="col">Income Type</th>
              </tr>
            </thead>
            <tbody>
              {userhistory?.length > 0 ? (
                userhistory?.map((details, index) => (
                  <tr key={index}>
                  
                    <th scope="row">{index + 1}</th>
                    <td>{details.n_amount}</td>
                    <td>{moment(details.n_approve_date).format("DD MMM YYYY")}</td>
                    <td>{moment(details.n_date).format("DD MMM YYYY")}</td>
                    <td>{details.n_income}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Widthhistory;
