import React, { useState, useContext, useEffect } from "react";
import "./Login.scss";
import Form from "react-bootstrap/Form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { userContext } from "../../context/Auth";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { generateToken, messaging } from "../../components/firebase/firebase";


import { onMessage } from "firebase/messaging";

const Login = () => {
  const {
    formData,
    setFormData,
    loginSubmit,
    ToastContainer,
    userlogin,
    setUserlogin,
  } = useContext(userContext);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };



  useEffect(() => {
    onMessage(messaging, (payload) => {console.log(payload,'payloaddddd')
  toast.success(payload.notification.body)

  
  });
    generateToken();
  }, []);

   // Registering the service worker
  //  if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker
  //     .register('../../../public/firebase-messaging-sw', { scope: '/' }) // Register with the correct scope
  //     .then((registration) => {
  //       console.log('Service Worker registered successfully:', registration);
  //     })
  //     .catch((error) => {
  //       console.error('Service Worker registration failed:', error);
  //     });
  // }



  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
  


  return (
    <div className="container-fluid logins">
      <form onSubmit={loginSubmit}>
        <h4>Log In</h4>
        <div className="logo">
          <img src="./images/main_logo.png" alt="" />
        </div>

        <div className="log-inputs">
          <div>
            <Form.Label htmlFor="email">User Id</Form.Label>
            <Form.Control
              type="text"
              name="referral_id"
              onChange={onHandleChange}
              id="referral_id"
              value={formData.referral_id}
              placeholder="Enter your referral id"
            />
          </div>

          <div className="password">
            <Form.Label htmlFor="password">Password</Form.Label>
            <Form.Control
              type={passwordVisible ? "text" : "password"}
              id="password"
              name="password"
              onChange={onHandleChange}
              value={formData.password}
              placeholder="Enter your password"
            />
            <div onClick={togglePasswordVisibility}>
              {passwordVisible ? (
                <FaEyeSlash className="closedeyes" />
              ) : (
                <FaEye className="openeyes" />
              )}
            </div>
            <NavLink to="/forgot">
              <div className="forgot">Forgot password?</div>
            </NavLink>
          </div>
        </div>
        <button type="submit" disabled={userlogin}>
          {userlogin ? (
            <>
              <Spinner animation="grow me-2" />
              Loging....
            </>
          ) : (
            "Log in"
          )}
        </button>

        <NavLink to="/register">
          <h6>Register an account</h6>
        </NavLink>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Login;
