import React, { useState, useEffect } from "react";
import { Tab, Tabs, Row, Col, Container, Spinner, Alert, Button } from "react-bootstrap";
import axios from "axios";
import "./Myproduct.scss";
import "../Myproducts/Products.css";

import { motion } from "framer-motion";

const Product = ({ product, handleQuantityChange, quantities, handleNavigate }) => (
  <Col xs={6} sm={6} md={4} lg={3} className="mb-4">
    <motion.article
      className="ezy__blog7-post"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300, damping: 15 }}
    >
      <div className="position-relative" onClick={() => handleNavigate(product.p_id)}>
        <img
          src={`https://lunarsenterprises.com:3004/mlm/${product.p_image}`}
          alt={product.p_name}
          className="ezy-blog7-banner"
        />
      </div>
      <div className="ms-2 p-md-2">
        <p className="mt-1 ezy__blog7-title fontwgt">Price : {product.p_price}</p>
        <p
          className={`mt-2 ${
            20 > 5
              ? "text-success"
              : product.cs_quality > 0
              ? "text-danger"
              : "text-muted"
          }`}
        >
          {product.cs_quality > 0
            ? product.cs_quality < 5
              ? `Hurry! Only ${product.cs_quality} left`
              : `Available: ${product.cs_quality}`
            : "Not Available"}
        </p>
        <div className="d-flex align-items-center mt-2">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => handleQuantityChange(product.p_id, -1)}
            disabled={quantities[product.p_id] <= 1}
          >
            -
          </Button>
          <span className="mx-3">{quantities[product.p_id]}</span>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => handleQuantityChange(product.p_id, 1)}
          >
            +
          </Button>
        </div>
      </div>
      <div className="buttoncenter mt-3 mb-2">
        <Button
          className="button-product"
          disabled={product.cs_quality <= 0}
          onClick={() => handleNavigate(product.p_id)}
        >
          <span className="Enroll">Buy Now</span>
        </Button>
      </div>
    </motion.article>
  </Col>
);

const Myproduct = () => {
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post("https://lunarsenterprises.com:3004/mlm/product/list");
        const result = response.data;

        if (result?.result) {
          const products = result.data.flatMap((category) => category.product);
          const initialQuantities = Object.fromEntries(
            products.map((product) => [product.p_id, 1])
          );
          setQuantities(initialQuantities);
          setCategories(result.data);
          setActiveTab(result.data[0]?.c_id.toString());
          setLoading(false);
        } else {
          throw new Error(result?.message || "Failed to fetch data.");
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleQuantityChange = (p_id, increment) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [p_id]: Math.max((prevQuantities[p_id] || 1) + increment, 1),
    }));
  };

  const handleNavigate = (p_id) => {
    console.log("Navigating to product with ID:", p_id);
    // Implement navigation logic here
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  }

  return (
    <section className="ezy__epgrid12 gray home">
      <Container>
        <Row className="justify-content-center">
          <Col lg={9}>
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
              {categories.map((category) => (
                <Tab key={category.c_id} eventKey={category.c_id.toString()} title={category.c_name}>
                  <Row>
                    {category.product.map((product) => (
                      <Product
                        key={product.p_id}
                        product={product}
                        handleQuantityChange={handleQuantityChange}
                        quantities={quantities}
                        handleNavigate={handleNavigate}
                      />
                    ))}
                  </Row>
                </Tab>
              ))}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Myproduct;
