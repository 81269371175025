//firebase.jsx
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyC7VSXTx8NMXccBSAHC7MphXs9oAF0dxRI",
  authDomain: "springway-56b2a.firebaseapp.com",
  projectId: "springway-56b2a",
  storageBucket: "springway-56b2a.firebasestorage.app",
  messagingSenderId: "933927662646",
  appId: "1:933927662646:web:9158dfa0369a87089f1497",
  measurementId: "G-C5P1HHWR6T",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log(permission, "grantt");

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BAktQSIzRHhyyHXxq0l2ceSqBf4zeWfzUH5RmHdR5VzjYZ74HPycIrB2K16695ipTRIDTdfVr6IRU7UJstcoS9M",
    });
    console.log(token);
  }
};
